import React from 'react'
import { Link } from 'gatsby'
import icon1 from '../../assets/images/services/service-icon1.png'
import icon2 from '../../assets/images/services/service-icon2.png'
import icon3 from '../../assets/images/services/service-icon3.png'

const RelatedServicesdw = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>More Services You Might Like</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsbd">
                                    Big Data
                                </Link>
                            </h3>
                            <p>Use our advanced analytics techniques to identify key patterns & insights in very large diverse data sets to drive business benefits.</p>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsmdm">
                                    Master Data Management
                                </Link>
                            </h3>
                            <p>Author, Deploy & safeguard master data management policies, principles, guidelines using our MDM framework & decades of experience.</p>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon3} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsdv">
                                    Data Visualization
                                </Link>
                            </h3>
                            <p>Highlight key business information in an easy to consume story telling visual dashboards using today's market leading data visualization tools.</p>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RelatedServicesdw