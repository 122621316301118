import React from 'react'
import ServiceSidebardw from './ServiceSidebardw'
import detailsdw from '../../assets/images/services/services-detailsdw.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentdw = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={detailsdw} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data Warehousing </span>
                            <h3>About this Service</h3>
                            <p>Frustrated by the manual process of interpreting raw data?</p>
                            <p>You have good, well-organized, and documented data streams, but for them to be useful, you need a team of BI experts to manually analyze and visualize into readable documents that your team can use.</p>
                            <p>Tired of the constant demands for more data?</p>
                            <p>What do you do when the controller, human resources, logistics manager, and sales VP all need different information against similar set of variables at the same time? Even with a team of analysts, the high demand for instant, real-time information feels unavoidable. </p>
                            <p>Fear opportunity loss due to lack of practical data?</p>
                            <p>If your business information cannot be accessed or understood by all parties, it can quickly become a useless burden. After all, your data is only useful when it can be used to better your organization and improve profit.</p>
                            <b>That's why our team has helped some of the nation's top Fortune 500 companies become more competitive, productive, and innovative by making their business information make sense.</b>
                            <p>Our team of BI & DWH experts will design and develop systems to help you leverage the power of your data:</p>
                            <li>Stay proactive with real-time data</li>
                            <li>Empower your team with easy-to-use dashboards</li>
                            <li>Access your data anywhere with mobile intelligence solutions</li>
                            <li>Make smarter decisions with predictive data models</li>
                            <li>Stay productive with fewer interruptions and faster answers</li>
                            <li>All on the platforms and technology that works best for you (including cloud business intelligence on AWS, Azure, Power BI, Oracle, DB2, Netezza, Teradata, SQL Server and more)</li>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>10+ Implementations</li>
                                            <li>All market leading DWH Tools</li>
                                            <li>20+ years of experience </li>
                                            

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Our 20+ years of business intelligence & data warehousing consulting experience allows us to see through convoluted systems and make sense of data in a way that's best for your business. From data analytics consulting (ex. BI dashboards) to enterprise data warehousing & modeling, we know that a guiding measurement of success for any BI strategy is adoption by the end users of the organization, and we strive to ensure these users are comfortable with the software.</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                                

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Insurance
                                    </div>
                                </div>

                                

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Health Care

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Oracle</span></li>
                                <li><span>Teradata</span></li>
                                <li><span>DB2</span></li>
                                <li><span>Netezza</span></li>
                                <li><span>MS Azure</span></li>
                                <li><span>AWS</span></li>
                                <li><span>Tableau</span></li>
                                <li><span>OAC</span></li>
                                <li><span>Qlikview</span></li>
                                <li><span>Microstrategy</span></li>
                                <li><span>BusinessObjects</span></li>
                                <li><span>MS Power BI</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebardw />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentdw